export default function useCouponManager() {
  const supabase = useSupabaseClient();

  const selectCoupon = async (couponId) => {
    try {
      const { data, error } = await supabase
        .from("coupons")
        .select(
          "*, coupon_comments(*), businesses(business_display_name, business_logo),  coupon_statuses(status)",
        )
        .eq("coupon_id", couponId);

      if (error) {
        throw new Error(`Error al seleccionar el cupón: ${error.message}`);
      }
      console.log(data);
      return data;
    } catch (error) {
      console.error(`Error al seleccionar el cupón con ID ${couponId}:`, error);
      return null;
    }
  };

  const selectCoupons = async (page, pageSize) => {
    try {
      const startIndex = (page - 1) * pageSize;

      const { data, error, count } = await supabase
        .from("coupons")
        .select("*, coupon_statuses(*), businesses(business_display_name)", {
          count: "exact",
        })
        .range(startIndex, startIndex + pageSize - 1)
        .order("updated_at", { ascending: false });

      if (error) {
        throw new Error(`Error al seleccionar el cupón: ${error.message}`);
      }

      return { data, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const selectCouponsByBusinessId = async (businessId, page, pageSize) => {
    try {
      const startIndex = (page - 1) * pageSize;

      const { data, error, count } = await supabase
        .from("coupons")
        .select(
          "*, coupon_statuses(*), businesses(business_display_name), coupon_redeems(*))",
          {
            count: "exact",
          },
        )
        .eq("business_id", businessId)
        .range(startIndex, startIndex + pageSize - 1)
        .order("updated_at", { ascending: false });

      if (error) {
        throw new Error(`Error al seleccionar el cupón: ${error.message}`);
      }

      return { data, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const selectCouponsNoDraft = async (page, pageSize, statuses, orderBy) => {
    statuses = statuses.map(Number);

    try {
      let ascending;
      let orderColumn;
      switch (orderBy) {
        case "updated_at_older":
          orderColumn = "updated_at";
          ascending = true;
          break;
        case "updated_at_newer":
          orderColumn = "updated_at";
          ascending = false;
          break;
        case "is_featured":
          orderColumn = "is_featured";
          ascending = false;
          break;
        case "title_az":
          orderColumn = "title";
          ascending = true;
          break;
        case "title_za":
          orderColumn = "title";
          ascending = false;
          break;
        default:
          orderColumn = "updated_at";
          ascending = false;
      }
      const startIndex = (page - 1) * pageSize;

      const { data, error, count } = await supabase
        .from("coupons")
        .select(
          "*, coupon_statuses(*), businesses(business_display_name), coupon_redeems(*)",
          {
            count: "exact",
          },
        )
        .in("status_id", statuses)
        .neq("status_id", 1)
        .range(startIndex, startIndex + pageSize - 1)
        .order(orderColumn, { ascending });

      if (error) {
        throw new Error(`Error al seleccionar el cupón: ${error.message}`);
      }

      return { data, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const selectCouponForWeb = async (couponId) => {
    try {
      const { data, error } = await supabase
        .from("coupons")
        .select(
          "*, businesses(business_branches(*), business_display_name, business_id, business_legal_name, business_rut, business_main_phone, business_secondary_phone, business_email ),coupon_redeems(*)",
        )
        .eq("coupon_id", couponId);

      if (error) {
        throw new Error(`Error al seleccionar el cupón: ${error.message}`);
      }

      return data;
    } catch (error) {
      console.error(`Error al seleccionar el cupón con ID ${couponId}:`, error);
    }
  };

  const selectCouponsForWeb = async (categories, ...values) => {
    try {
      if (values.length > 0) {
        // Asegúrate de que los valores sean un array de números
        values = Array.isArray(values) ? values.map(Number) : [Number(values)];
      }
      if (categories) {
        // Asegúrate de que las categorías sean un array de números
        categories = Array.isArray(categories)
          ? categories.map(Number)
          : [Number(categories)];
      }

      const query = supabase
        .from("coupons")
        .select(
          "*, businesses(business_display_name, business_logo, business_id),coupon_redeems(*)",
        )
        .eq("status_id", 5)
        .order("updated_at", { ascending: false })
        .order("is_featured", { ascending: false });

      const { data: allData, error: allError } = await query;

      if (allError) {
        throw new Error(`Error al seleccionar el cupón: ${allError.message}`);
      }

      // let filteredData = allData.filter((coupon) => {
      //   const redeemCountSum = coupon.coupon_redeems.reduce(
      //     (sum, redeem) => sum + redeem.redeem_count,
      //     0,
      //   );
      //   if (
      //     coupon.max_redeems_per_promo === 0 ||
      //     coupon.coupon_redeems.length === 0 ||
      //     redeemCountSum < coupon.max_redeems_per_promo
      //   ) {
      //     return true;
      //   }
      // });

      // if (values.length > 0) {
      //   filteredData = filteredData.filter((item) =>
      //     values.some((v) => item.values.includes(v)),
      //   );
      // }
      // if (categories) {
      //   filteredData = filteredData.filter((item) =>
      //     categories.some((c) => item.categories.includes(c)),
      //   );
      // }

      // return filteredData;

      return allData;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const updateCoupon = async (couponId, valuesToUpdate) => {
    try {
      const { data, error } = await supabase
        .from("coupons")
        .update({ updated_at: new Date(), ...valuesToUpdate })
        .eq("coupon_id", couponId)
        .select();

      if (error) {
        throw new Error(`Error al actualizar el cupón: ${error.message}`);
      }
      return data;
    } catch (error) {
      console.error(`Error al actualizar el cupón con ID ${couponId}:`, error);
      return null;
    }
  };

  const insertCoupon = async (valuesToInsert) => {
    try {
      const { data, error } = await supabase
        .from("coupons")
        .insert({
          created_at: new Date(),
          updated_at: new Date(),
          ...valuesToInsert,
        })
        .select();

      if (error) {
        throw new Error(`Error al insertar el cupón: ${error.message}`);
      }
      return data;
    } catch (error) {
      console.error("Error al insertar el cupón:", error);
      return null;
    }
  };

  const deleteCoupon = async (couponId) => {
    try {
      const { error } = await supabase
        .from("coupons")
        .delete()
        .eq("coupon_id", couponId);

      if (error) {
        throw new Error(`Error al eliminar el cupón: ${error.message}`);
      } else {
        return true;
      }
    } catch (error) {
      console.error(`Error al eliminar el cupón con ID ${couponId}:`, error);
      return null;
    }
  };

  return {
    selectCoupon,
    selectCoupons,
    selectCouponsNoDraft,
    selectCouponsByBusinessId,
    updateCoupon,
    insertCoupon,
    deleteCoupon,
    selectCouponForWeb,
    selectCouponsForWeb,
  };
}
